import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function WhyUs() {
  const pageHeader = React.createRef();
  return (
    <div
      style={{
        backgroundColor: '#f0f0f0',
      }}
      className="section"
      data-parallax
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className="text-center text-300 pb-4">
              WHY CHOOSE
              {' '}
              <b>CRIoT SOLUTIONS?</b>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10}>
            <h5
              className="text-300 text-center mt-0 py-4"
              style={{
                borderBottomStyle: 'solid',
                borderColor: '#e0e0e0',
                borderBottomWidth: '1px',
              }}
            >
              CRIoT Solutions delivers a dynamic, adaptive, connected supply chain that allows you
              to optimize multi-tiered distribution management while minimizing supply disruption.
              CRIoT Solutions helps you run an efficient operation, and provide better service to
              customers.
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WhyUs;
