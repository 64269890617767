import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import BackgroundSection from '../assets/images/mesh_bg-blue.png';

function Offerings() {
  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundSection})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'center bottom',
      }}
      data-parallax
    >
      <Container>
        <Row className="py-4">
          <Col lg={12} className="text-center" style={{ paddingBottom: '100px' }}>
            <h3 className="text-300 pt-4">
              UNLEASH INSIGHTS AND VISIBILITY TO ALL OF YOUR FLUID INVENTORY MANAGEMENT WITHIN YOUR
              SUPPLY CHAIN TO INCREASE PROFITS, EFFICIENCY AND SECURE YOUR COMPETITIVE EDGE WITH
            </h3>
            <h2 className="text-700 mt-1 pb-4">THE CRIoT SOLUTIONS</h2>
            <h5 className="mt-3">
              Leverage our deep machine learning, predictive analytics and forecasting tools to
              extract actionable insights. Know what, when and how much to be delivered at the touch
              of your fingertips from anywhere you are.
            </h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Offerings;
