import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './assets/css/bootstrap.min.css';
// import './assets/scss/paper-kit.scss';
import './assets/css/paper-kit.min.css';
import './assets/demo/demo.css';
import PrivacyPolicy from './components/PrivacyPolicy';
import BulkFluidMonitoring from './components/BulkFluidMonitoring';
import Home from './components/Home';
import HardGoodsTracking from './components/HardGoodsTracking';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route
        path="/privacy-policy"
        exact
        render={props => <PrivacyPolicy {...props} />}
      />
      <Route
        path="/bulk-fluid-monitoring"
        exact
        render={props => <BulkFluidMonitoring {...props} />}
      />
      <Route path="/hard-goods-tracking" exact render={props => <HardGoodsTracking {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);
