import React, { Fragment } from 'react';
import ScrollUpButton from 'react-scroll-up-button';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import Navbar from './Navbar';
import Footer from './Footer';

const content = [
  {
    title: 'Independent Dealers',
    image: 'Quick-Lube.png',
    paragraphs: [
      'Gone are the days of relying on dipsticks, confusing formulas, and dusty paper trails to estimate how much oil, antifreeze, windshield washer fluid, etc. are in each tank. With CRIoT Solutions, each tank or tote level is accurately captured throughout the day and uploaded to the CRIoT Solutions cloud-based app – so that you know exactly how many gallons you purchased, as well as when you will run out and need to order more product.',
      'No more product outages, no more tanks overflowing.',
      'One-click shows you which product grade is more profitable, and which of your stores are run best.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
  {
    title: 'Distributors',
    image: 'OilTanker.png',
    paragraphs: [
      'One glance at the CRIoT Solutions app gives you access to tank or tote levels for each of your customer locations. In real-time.',
      'You can optimize your routes to reduce costs and eliminate unnecessary stops.  Now you can easily grow sales with insights such as knowing which independent dealers most profitable, which products are is in the highest demand, and what areas you need to focus your efforts to enhance your operation.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
  {
    title: 'Manufacturers',
    image: 'data-tablet.png',
    paragraphs: [
      'Today, you have detailed information about your production line down to each delivery to your distributors.',
      'It’s good. But it’s not enough.',
      'CRIoT Solutions gives you access to information down to each retail location’s individual product velocity by accurately measuring the level in each container throughout the day. With the equivalent of Point Of Sale data finally, at your fingertips, CRIoT Solutions bulk fluid monitoring solution provides detailed information for bulk products that rivals the information you are accustomed to receiving for packaged products.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
];

function BulkFluidMonitoring() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div id="outer-container" style={{ marginTop: '120px' }}>
      <Navbar />
      <ScrollUpButton />
      <Container>
        <Row className="py-2">
          <Col lg={12}>
            <h3 className="text-center text-700 py-4" style={{ color: '#005cb9' }}>
              Bulk Fluid Supply Monitoring
            </h3>
            <h5>
              Bulk Fluid Supply Monitoring CRIoT Solutions bulk fluid monitoring solution delivers
              real-time visibility to all bulk fluid storage containers levels, including motor oil,
              and lubricants, helping you better manage your inventory needs, schedule deliveries,
              understand your best performers, top markets, and make faster, better-informed
              decisions.
            </h5>
          </Col>
        </Row>
        {content.map((each, i) => (
          <Fragment key={i}>
            <Row>
              <Col lg={12}>
                <h4 className="text-400" style={{ color: '#005cb9' }}>
                  {each.title}
                </h4>
                <hr />
              </Col>
            </Row>
            <Row className="my-3 py-4 align-items-center justify-content-between">
              <Col lg={4}>
                <img
                  className="mb-5"
                  src={require(`../assets/images/solutions/${each.image}`)}
                  alt={`sol-${i}`}
                  width="100%"
                />
              </Col>
              <Col lg={7}>
                {/* <h4 style={{ color: '#005cb9' }}>{each.title}</h4> */}
                {/* <hr /> */}
                {each.paragraphs.map(p => (
                  <p>{p}</p>
                ))}
                <a className="text-white" target="_self" href={each.link}>
                  <Button
                    className="my-2"
                    style={{
                      backgroundColor: '#005cb9',
                      borderRadius: '0px',
                      borderColor: '#005cb9',
                    }}
                  >
                    CLICK HERE TO GET STARTED
                  </Button>
                </a>
              </Col>
            </Row>
            {/* {i < content.length - 1 && <hr width="50%" className="my-5" />} */}
          </Fragment>
        ))}
      </Container>
      <Footer />
    </div>
  );
}

export default BulkFluidMonitoring;
