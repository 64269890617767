// reactstrap components
import { Container, Col, Row } from 'reactstrap';

import React, { useState, useEffect, useRef } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function LandingPageHeader() {
  const size = !!(
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  );

  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        window.VANTA.NET({
          el: myRef.current,
          color: '#005fad',
          backgroundColor: '#00030c',
          mouseControls: true,
          touchControls: true,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
        }),
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div
      className="page-header"
      style={{
        background: '#ffffff',
        alignItems: size ? 'start' : 'center',
        paddingTop: !size && '10rem',
      }}
      ref={myRef}
    >
      <Container className="align-self-center">
        <Row className="align-items-start">
          <Col md={10} xs={12} className="p-2">
            <ScrollAnimation animateIn="fadeIn" animateOnce duration={0.5} initiallyVisible>
              <ScrollAnimation
                animateIn="fadeInDownBig"
                animateOnce
                duration={0.5}
                initiallyVisible
              >
                <h1
                  style={
                    size
                      ? {
                        color: '#ffffff',
                        fontSize: '100px',
                      }
                      : {
                        color: '#ffffff',
                        fontSize: '60px',
                      }
                  }
                  className="text-600-a mt-0"
                >
                  CRIoT Solutions
                </h1>
                <br />
                <br />
                <h3 className="text-700 text-white mt-0">
                  REVOLUTIONIZING THE FLUID MANAGEMENT SUPPLY CHAIN.
                </h3>
                {/* <br />
                <h5 className="text-400 text-white mt-0">
                  VISIBILITY & INSIGHTS UNLEASHES EFFICIENCY, INCREASES PROFITS, AND SECURES YOUR
                  COMPETITIVE EDGE.
                  <br />
                  <br />
                  CRIoT Solutions is a remarkable end-to-end solution that provides real time-supply
                  chain visibility by accurately and cost-effectively capturing the right data –
                  from manufacturer, to distributor, and on to the individual retailer.
                  <br />
                  <br />
                  CRIoT Solutions Leverages deep-learning algorithms and predictive analytics to
                  extract actionable insights.
                </h5> */}
              </ScrollAnimation>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LandingPageHeader;
