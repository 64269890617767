import React from 'react';
import {
  Container, Row, Col, Card, CardText, CardBody,
} from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroundSection from '../assets/images/tech-why-bg.png';
import Sphere from '../assets/images/sphere3.png';
import SphereShadow from '../assets/images/Sphere-Shadow.png';

const pillars = [
  {
    title: 'The Hardware',
    subtitle: 'The heart of the system.',
    body:
      'Our hardware accurately captures all relevant information. Our systems are designed to be deployed rapidly while remaining cost-effective enough to deploy on a massive scale. Our platform securely transfers the information from the sensors to the cloud.',
    image: '1.png',
  },
  {
    title: 'The Platform',
    subtitle: 'The brain of the system.',
    body:
      'Our state-of-the-art platform includes proprietary algorithms and analytics that convert data points into valuable insights. AI and machine learning will translate your data into actionable decision making resources for forecasting, trends and product insights.',
    image: '2.png',
  },
  {
    title: 'Installation',
    subtitle: 'The muscle of the system.',
    body:
      'Installation of our hardware is performed by trained, expert technicians, who also train you on how to use the CRIoT intuitive App.  Rest assured the solution is deployed right the first time, so you can rely on it to do its job day in and day out.',
    image: '3.png',
  },
  {
    title: 'Support',
    subtitle: 'The body of the system.',
    body:
      'Your business is supported, from installation to consumption –The team continuously monitors, troubleshoots, diagnoses and resolves any issues. Enjoy peace of mind knowing that you receive actionable, impactful insights when you need them.',
    image: '4.png',
  },
];

const features = [
  { title: 'Integration', body: 'built for speed and scale' },
  { title: 'Dynamic', body: 'adaptive connected supply chain' },
  {
    title: 'Optimized',
    body: 'multi-tiered distribution management, minimizing supply disruption',
  },
  { title: 'Efficient', body: 'provide better service to customers' },
];

const borderRadius = '0px';

function Features() {
  const icons = ['fa fa-hdd-o', 'fa fa-laptop', 'fa fa-cogs', 'fa fa-headphones'];
  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundSection})`,
        backgroundRepeat: 'repeat-x',
        backgroundColor: '#f0f0f0',
        backgroundPosition: 'center bottom',
      }}
      data-parallax
    >
      <Container>
        <Row className="justify-content-between">
          <Col lg={6}>
            {features.map(s => (
              <ScrollAnimation
                key={s.title}
                className="mt-4"
                animateIn="slideInLeft"
                animateOnce
                duration={0.5}
              >
                <div>
                  <h1 className="text-center my-2" style={{ color: '#005cb9' }}>
                    {s.title}
                  </h1>
                  <p className="card-text text-center my-4">{s.body}</p>
                </div>
                <div
                  className="my-2 mx-5"
                  style={{ borderBottomStyle: 'dotted', borderColor: '#e0dede' }}
                />
              </ScrollAnimation>
            ))}
            <Col>
              <img src={Sphere} className="img-responsive mt-5" alt="sphere" width="100%" />
              <img
                src={SphereShadow}
                className="img-responsive mt-5"
                alt="sphere-shadow"
                width="100%"
              />
            </Col>
          </Col>
          <Col lg={6} style={{ marginBottom: '200px' }}>
            <Col lg={12}>
              <h3 className="text-center pb-4">The Four Pillars of the CRIoT Solutions</h3>
            </Col>
            {pillars.map((s, i) => {
              const backgroundColor = i % 2 === 1 ? '#005cb9' : undefined;
              const color = i % 2 === 1 ? '#ffffff' : '#5c5c5c';
              return (
                <ScrollAnimation
                  key={i}
                  className="mt-2"
                  animateIn="slideInRight"
                  animateOnce
                  duration={0.5}
                >
                  <Card style={{ backgroundColor, borderRadius }}>
                    <CardBody>
                      {/* <img src={image} width="60" alt="icon" /> */}
                      <i className={icons[i]} style={{ color, fontSize: '38px' }} />
                      <h5 className="text-700 my-2" style={{ color }}>
                        {s.title}
                      </h5>
                      <CardText className="text-400 my-1" style={{ color }}>
                        {s.subtitle || ''}
                      </CardText>
                      <CardText style={{ color }}>{s.body}</CardText>
                    </CardBody>
                  </Card>
                </ScrollAnimation>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Features;
