import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';

const backgroundColor = '#1588d7';

function Social() {
  return (
    <Row className="justify-content-between">
      <ScrollAnimation key={1} animateIn="slideInLeft" animateOnce duration={0.5}>
        <Col lg={12} className="text-white">
          <h5 className="mt-0">Follow CRIoT Solutions On Social Media</h5>
          <h5>Join Us To Revolutionize The Supply Chain</h5>
        </Col>
      </ScrollAnimation>

      <ScrollAnimation key={2} animateIn="slideInRight" animateOnce duration={0.5}>
        <Col lg={12} className="text-center py-3">
          <Button
            className="btn-neutral btn-just-icon mr-1"
            style={{ color: backgroundColor }}
            href="https://www.facebook.com/RIoTSolution"
          >
            <i className="fa fa-facebook" />
          </Button>
          <Button
            className="btn-neutral btn-just-icon mr-1"
            style={{ color: backgroundColor }}
            href="https://twitter.com/RiotSolution"
          >
            <i className="fa fa-twitter" />
          </Button>
          <Button
            className="btn-neutral btn-just-icon mr-1"
            style={{ color: backgroundColor }}
            href="#"
          >
            <i className="fa fa-linkedin" />
          </Button>
        </Col>
      </ScrollAnimation>
    </Row>
  );
}

export default Social;
