import React from 'react';
import {
  Container,
  Row,
  Col,
  Input,
  Form,
  Button,
  Spinner,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';

const backgroundColor = '#246dd6';
const borderRadius = '0px';

function Contact() {
  const [details, setDetails] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  document.documentElement.classList.remove('nav-open');

  React.useEffect(() => {
    document.body.classList.add('register-page');
    return function cleanup() {
      document.body.classList.remove('register-page');
    };
  });
  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    const key = process.env.REACT_APP_FUNCTION_KEY;

    try {
      const res = await fetch(
        'https://criot-dev-functions.azurewebsites.net/api/ContactUsHttpTrigger',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'x-functions-key': key,
          },
          body: JSON.stringify(details),
        },
      );

      if (res.ok) {
        setLoading(false);
        setSuccess("Thank you for your request. We'll get back to you shortly.");
      }
    } catch (err) {
      setLoading(false);
      setError('Something went wrong please try again');
    } finally {
      setDetails({
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        message: '',
      });
    }
  };

  return (
    <div style={{ backgroundColor }} className="section" data-parallax>
      <Container>
        <Row>
          <Col lg={12} className="text-center text-white">
            <h2 className="text-300 pb-4">
              CONTACT
              {' '}
              <b>CRIoT SOLUTIONS</b>
              {' '}
TODAY
            </h2>
            <h4 className="text-300 pb-4">
              Don’t be shy, simply fill out the form below. We will get back to you in no time.
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={6} className="my-auto text-white text-uppercase text-400">
            <Card style={{ backgroundColor: '#e5e5e5', borderRadius }}>
              <CardBody>
                <Form onSubmit={onSubmitForm} className="register-form">
                  <Row>
                    <Col lg={6} className="my-2">
                      <label>First Name*</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="first_name"
                        value={details.first_name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6} className="my-2">
                      <label>Last Name*</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="last_name"
                        value={details.last_name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6} className="my-2">
                      <label>Email*</label>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={details.email}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6} className="my-2">
                      <label>Company*</label>
                      <Input
                        placeholder="Company"
                        type="text"
                        name="company"
                        value={details.company}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={12} className="my-2">
                      <label>Comment/Message</label>
                      <Input
                        placeholder="Comment/Message"
                        type="textarea"
                        name="message"
                        value={details.message}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  </Row>

                  <Button
                    block
                    className="btn text-white text-700 mt-2"
                    style={{ backgroundColor: '#005cb9', borderRadius, borderColor: '#005cb9' }}
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading ? (
                      <span>
                        {' '}
                        <Spinner color="warning" size="sm" />
                      </span>
                    ) : (
                      ''
                    )}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {error ? (
        <Alert color="danger" isOpen fade className="text-center" style={{ marginTop: '1rem' }}>
          {error}
        </Alert>
      ) : (
        ''
      )}
      {success ? (
        <Alert color="success" isOpen fade className="text-center" style={{ marginTop: '1rem' }}>
          {success}
        </Alert>
      ) : (
        ''
      )}
    </div>
  );
}

export default Contact;
