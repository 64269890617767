import React from 'react';
import {
  Container, Row, Col, Card, CardText, CardBody, Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroundSection from '../assets/images/cutting-edge-bg-blue.png';

export const solutions = [
  {
    title: 'Bulk Fluid Monitoring',
    subtitle: 'Optimize your supply chain performance.',
    body: 'Know the bulk fluid levels at every container throughout the supply chain.',
    link: 'bulk-fluid-monitoring',
  },
  {
    title: 'Hard Goods Tracking',
    subtitle: 'Maximize your supply chain performance.',
    body: 'Know exactly where each item is located, in real time, accurately.',
    link: 'hard-goods-tracking',
  },
];
const backgroundColor = '#eae9e9';
const borderRadius = '0px';

function Solutions() {
  return (
    <div
      className="section"
      style={{
        backgroundImage: `url(${BackgroundSection})`,
        backgroundRepeat: 'repeat-x',
        backgroundColor: '#005cb9',
        backgroundPosition: 'center bottom',
      }}
      data-parallax
    >
      <Container>
        <Row>
          <Col lg={12} className="text-center text-white">
            <h2 className="text-300 pb-4">
              CUTTING-EDGE
              {' '}
              <b>SOLUTIONS</b>
            </h2>
            <h3 className="text-300 pb-4">
              Providing complete visibility to fluid inventory management to create complete supply
              chain efficiency.
            </h3>
            <h5>
              CRIoT Solutions brings the best sensors for your needs, uses the most reliable
              hardware to transfer the data to our cloud-based app and use powerful AI & predictive
              analytic tools to deliver actionable insights.
            </h5>
            <h5>
              Any stakeholder - Manufacturers, distributors and independent dealers - can access
              inventory levels in real-time.
            </h5>
            <h5>Dynamically and Accurately</h5>
            <h5>Make Better Decisions, Decrease Cost, Increase Profits, and Save Time</h5>
          </Col>
        </Row>
        <Row className="justify-content-between my-5">
          {solutions.map((s, i) => (
            <Col xs={12} lg={6} key={i}>
              <ScrollAnimation className="mt-2" animateIn="slideInUp" animateOnce duration={0.5}>
                <Card className="text-center" style={{ backgroundColor, borderRadius }}>
                  <CardBody>
                    <h5 className="text-700 my-2">{s.title}</h5>
                    <CardText>{s.body}</CardText>
                    <CardText className="my-1">
                      <b>{s.subtitle}</b>
                    </CardText>
                    <Link to={s.link}>
                      <Button
                        style={{ backgroundColor: '#005cb9', borderRadius, borderColor: '#005cb9' }}
                      >
                        Read More
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Solutions;
