import React from 'react';
import {
  Container, Row, Col, Card, CardText, CardBody,
} from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import BackgroundSection from '../assets/images/mesh_bg-blue.png';

export const team = [
  {
    title: 'LIZ TOWER',
    subtitle: 'Chief Executive Officer & Co-Founder',
    mail: 'Liz@CRIoTSolutions.com',
    body:
      '30 years in retail, oil & CPG business development. Managed Fortune 50® global brands’ strategic expansions. Managed a national oil storage solution.',
    image: 'Liz.png',
  },
  {
    title: 'CATHY KING',
    subtitle: 'Chief Financial Officer / Chief Operations Officer / Owner',
    mail: 'Cathy@CRIotSolutions.com',
    body:
      '30+ years of financial and operational management in Fortune 500 companies. Managed ERP deployments, Financial and Human Resources integrated platforms with large brands at Pepsi, Ameriserve, Safety-Kleen & Jacobs Engineering.',
    image: 'Cathy.jpg',
  },
  {
    title: 'TRENT CARRENDER',
    subtitle: 'Chief Innovation Officer & Co-Founder',
    mail: 'Trent@CRIoTSolutions.com',
    body:
      '15+ years as an innovation entrepreneur. Developed operational technology solutions for Fortune 50 retail & CPG companies.',
    image: 'Trent.png',
  },
];
const backgroundColor = '#246dd6';
const borderRadius = '0px';

function Team() {
  return (
    <div
      className="section"
      style={{
        backgroundImage: `url(${BackgroundSection})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'center bottom',
      }}
      data-parallax
    >
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="text-300 pb-4">
              MEET THE
              {' '}
              <b>TEAM</b>
            </h2>
            <h4 className="text-300 pb-4">
              The CRIoT Solutions team is composed of engineers, magicians, and dreamers, who put
              their talents, hard work and ingenuity to bring innovative, end-to-end, IoT solutions
              that eradicate supply chain roadblocks, turn insanely complicated jumble of data into
              clean, concise actionable insights.
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-between mt-5" style={{ paddingBottom: '100px' }}>
          {team.map((s, i) => (
            <Col xs={12} lg={4} key={i}>
              <ScrollAnimation
                animateIn="slideInUp"
                animateOnce
                duration={0.5}
                style={{ height: '100%' }}
              >
                <Card
                  className="text-center my-2"
                  style={{ backgroundColor, borderRadius, height: '100%' }}
                >
                  <CardBody>
                    <h5 className="text-700 text-white my-2">{s.title}</h5>
                    <CardText className="my-1 text-white">
                      <b>{s.subtitle}</b>
                    </CardText>
                    <a
                      className="text-white"
                      href={`mailto:${s.mail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {s.mail}
                    </a>
                    <div>
                      <img
                        className="my-3"
                        src={require(`../assets/images/team/${s.image}`)}
                        alt="icon"
                        style={{ borderRadius, height: 228 }}
                      />
                    </div>
                    <CardText className="text-white">{s.body}</CardText>
                  </CardBody>
                </Card>
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Team;
