/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import HeaderLogo from '../assets/images/logo/header-logo.png';

const menu = [
  {
    name: 'HOME',
    link: 'home',
    page: '/',
    items: undefined,
    grid: 1,
  },
  {
    name: 'WHY US',
    link: 'why-us',
    page: '/',
    items: undefined,
    grid: 1,
  },
  {
    name: 'SOLUTIONS',
    link: 'solutions',
    page: '/',
    items: undefined,
    grid: 1,
  },
  {
    name: 'TEAM',
    link: 'team',
    page: '/',
    items: undefined,
    grid: 1,
  },
  {
    name: 'CONTACT US',
    link: 'contact-us',
    page: '/',
    items: undefined,
    grid: 1,
  },
  {
    name: 'PORTAL',
    link: 'https://demo.criotsolutions.com/login',
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: 'ABOUT',
  //   link: 'about',
  //   page: undefined,
  //   items: [
  //     { name: 'FINNOVEX', link: 'https://finnovex.com/me/#Aboutfinnovex' },
  //     { name: 'EXIBEX', link: 'http://exibex.com/' },
  //   ],
  //   grid: 1,
  // },
  // {
  //   name: '#FINNOVEXLIVE',
  //   link: 'https://finnovex.com/#FinnovexLive',
  //   page: undefined,
  //   items: undefined,
  //   grid: 2,
  // },
  // {
  //   name: 'SPEAKERS', link: 'speakers', page: '/', items: undefined, grid: 1,
  // },
  // {
  //   name: 'PARTNERS', link: 'partners', page: '/', items: undefined, grid: 1,
  // },
  // {
  //   name: 'DELEGATES',
  //   link: 'partners',
  //   page: '/',
  //   items: [
  //     { name: 'WHO ATTENDS', link: '/delegates/2' },
  //     { name: 'WHY ATTEND', link: '/delegates/3' },
  //   ],
  //   grid: 1,
  // },
];

function NavbarDiv() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  return (
    <Navbar
      className={classnames('fixed-top border-bottom')}
      style={{ height: '120px' }}
      color-on-scroll="300"
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-translate">
          <NavbarBrand data-placement="bottom" to="/" className="p-0" tag={Link}>
            <img
              src={HeaderLogo}
              className=""
              width="100%"
              height="54px"
              // style={{ height: '54px' maxWidth: '250px' }}
              alt="logo"
            />
          </NavbarBrand>
          <button
            type="button"
            aria-expanded={navbarCollapse}
            className={classnames('navbar-toggler navbar-toggler', {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1 text-white" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            {menu.map((link, i) => (!link.items ? (
              <NavItem key={i}>
                {window.location.pathname === '/' && link.page === '/' ? (
                  <AnchorLink
                    offset="100"
                    href={`#${link.link}`}
                    onClick={toggleNavbarCollapse}
                    className="nav-link navbar-item-custom text-400 text-decoration-none"
                  >
                    {link.name}
                    <hr className="m-0 mt-1 p-0" align="center" />
                  </AnchorLink>
                ) : link.page ? (
                  <a href={`/#${link.link}`} className="nav-link navbar-item-custom text-400">
                    {link.name}
                    <hr className="m-0 mt-1 p-0" align="center" />
                  </a>
                ) : (
                  <a href={`${link.link}`} className="nav-link navbar-item-custom text-400">
                    {link.name}
                    <hr className="m-0 mt-1 p-0" align="center" />
                  </a>
                )}
              </NavItem>
            ) : (
              <UncontrolledDropdown nav inNavbar key={i}>
                <DropdownToggle nav caret>
                  {link.name}
                </DropdownToggle>
                <DropdownMenu right>
                  {link.items.map(i => (
                    <DropdownItem href={i.link} key={i.link}>
                      {i.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            )))}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarDiv;
