import React from 'react';
import { Row, Container, Col } from 'reactstrap';

function Footer() {
  return (
    <footer
      style={{ backgroundColor: '#505152' }}
      className="footer footer-black footer-white text-white"
    >
      <Container fluid>
        <Row>
          <Col xs={12} className="text-center text-white py-2">
            <p className="text-400 mb-0">
              ©
              {' '}
              {new Date().getFullYear()}
              {' '}
| CRIoT SOLUTIONS | All Rights Reserved
              {' | '}
              <a href="/privacy-policy" className="text-400 text-white">Privacy Policy</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
