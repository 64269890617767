import React, { Fragment } from 'react';
import ScrollUpButton from 'react-scroll-up-button';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import Navbar from './Navbar';
import Footer from './Footer';

const content = [
  {
    title: 'Hard Goods Independent dealer',
    image: 'TruckWarehouse.png',
    paragraphs: [
      'CRIoT Solutions ensures that you always have the right products on the shelves at the right time. No more guessing or hoping your distributor will swing by with the items you need. Never have to incur the cost of special orders to fulfill customers’ demands. The CRIoT Solutions App tells you when it is time to replenish your assortment and alerts your distributor at the same time.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
  {
    title: 'Distributors',
    image: 'FreightTruck.png',
    paragraphs: [
      'Imagine knowing exactly what each of your independent dealers sells in real-time. Gone are the days of running a route based on estimates. You can plan in confidence, optimize each truck route and eliminate millions of miles per year. With CRIoT Solutions App, you know how long each product has been on the shelves, tie point of sale data to incentive programs and fine-tune your marketing programs.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
  {
    title: 'Manufacturers',
    image: 'Distribution.png',
    paragraphs: [
      'CRIoT Solutions delivers real-time visibility to all goods inventory levels throughout the supply chain, from inventory at your distributors and gain new insights by knowing inventory at each independent dealer allowing you to better manage your inventory needs, optimize deliveries, access Point-of-Sale equivalent data and run an agile supply chain.',
    ],
    link: 'mailto: Liz@criotsolutions.com',
  },
];

function HardGoodsTracking() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div id="outer-container" style={{ marginTop: '120px' }}>
      <Navbar />
      <ScrollUpButton />
      <Container>
        <Row className="py-2">
          <Col lg={12}>
            <h3 className="text-center text-700 py-4" style={{ color: '#005cb9' }}>
              Hard Goods Tracking
            </h3>
            <h5>
              CRIoT Solutions hard goods tracking solution delivers real-time visibility to every
              item throughout the supply chain, delivering unprecedented clarity so you can run an
              agile supply chain. Better manage your inventory needs, optimize delivery routes,
              reduce costs, and provide better customer service.
            </h5>
          </Col>
        </Row>
        {content.map((each, i) => (
          <Fragment key={i}>
            <Row>
              <Col lg={12}>
                <h4 className="text-400" style={{ color: '#005cb9' }}>
                  {each.title}
                </h4>
                <hr />
              </Col>
            </Row>
            <Row className="my-3 py-4 align-items-center justify-content-between">
              <Col lg={4}>
                <img
                  className="mb-5"
                  src={require(`../assets/images/solutions/${each.image}`)}
                  alt={`sol-${i}`}
                  width="100%"
                />
              </Col>
              <Col lg={7}>
                {/* <h4 style={{ color: '#005cb9' }}>{each.title}</h4> */}
                {/* <hr /> */}
                {each.paragraphs.map(p => (
                  <p>{p}</p>
                ))}
                <a className="text-white" target="_self" href={each.link}>
                  <Button
                    className="my-2"
                    style={{
                      backgroundColor: '#005cb9',
                      borderRadius: '0px',
                      borderColor: '#005cb9',
                    }}
                  >
                    CLICK HERE TO GET STARTED
                  </Button>
                </a>
              </Col>
            </Row>
            {/* {i < content.length - 1 && <hr width="50%" className="my-5" />} */}
          </Fragment>
        ))}
      </Container>
      <Footer />
    </div>
  );
}

export default HardGoodsTracking;
