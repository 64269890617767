import React from 'react';
import ScrollUpButton from 'react-scroll-up-button';
import { Col, Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import Offerings from './Offerings';
import Navbar from './Navbar';
import LandingPageHeader from './LandingPageHeader';
import WhyUs from './WhyUs';
import Features from './Features';
import Solutions from './Solutions';
import Team from './Team';
import Contact from './Contact';
import Social from './Social';
import Location from './Location';
import Footer from './Footer';

function Home() {
  return (
    <div id="outer-container">
      <ScrollUpButton />
      <Navbar page="home" />
      <section id="home" />
      <LandingPageHeader />
      <Offerings />

      <section id="why-us" />
      <WhyUs />
      <Features />

      <section id="solutions" />
      <Solutions />

      <section id="team" />
      <Team />

      <section id="contact-us" />
      <Contact />
      <div style={{ backgroundColor: '#1588d7' }}>
        <Container>
          <Row className="justify-content-around align-items-center px-3 py-5">
            <Col xs={10} md={5} lg={4}>
              <Social />
            </Col>
            <Col xs={10} md={5} lg={4}>
              <Location />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
