import React from 'react';


function Location() {
  return (
    <div data-parallax>
      <h3 className="text-300 pb-4">
        <b>CRIoT SOLUTIONS HQ</b>
      </h3>
      <h5>2613 Pasadena Place</h5>
      <h5>Flower Mound, Texas 75022</h5>
      <h5>Phone: 214.213.7523</h5>
      <h5>
        Email:
        {' '}
        <a className="text-white" href="mailto:info@criotsolutions.com">
          info@criotsolutions.com
        </a>
      </h5>
    </div>
  );
}

export default Location;
